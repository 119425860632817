/*
 * @Description: 系统工具类
 * @Author: Pancras
 * @Date: 2019-10-21 10:13:41
 * @LastEditors: yjk
 * @LastEditTime: 2023-05-12 14:32:34
 */
import store from "@/store/index";
import cookies from "./util.cookies";
import sessions from "./util.sessions";
import security from "./util.security";
import db from "./util.db";
import debounce from "./util.debounce";
import dayjs from "dayjs";
import area from "@/assets/json/area.json";

import UpdateLocale from "dayjs/plugin/updateLocale";
import { _GetUserApprove } from "@/api/user";
dayjs.extend(UpdateLocale);

const util = {
  cookies,
  sessions,
  db,
  security,
  debounce,
};
/**
 * getToken
 * @description 从 sessions 中获取用户 token
 * @return {Object}   返回用户 Token
 * @example 调用示例
 *  let token = util.getToken()
 */
util.getToken = () => {
  let token;
  try {
    token = JSON.parse(util.cookies.get("token"));
  } catch (ex) {
    token = undefined;
  }
  return token;
};

/**
 * getApproveStatus
 * @description 从 获取用户认证状态
 * @return {Object}   返回用户 状态值
 * @example 调用示例
 *  let status = util.getApproveStatus()
 */
util.getApproveStatus = () => {
  let status;
  _GetUserApprove()
    .then((res) => {
      console.log(res);
      status = res.isAuthentication;
      return status;
    })
    .catch((err) => {
      console.log(err);
      status = undefined;
      return status;
    });
};

/**
 * getExpiresOn
 * @description  根据 expires_in 获取过期时间
 * @return {Number} expiresOn 过期日期与'1970/01/01'之间的毫秒值
 * @example 调用示例
 *  let token = util.getExpiresOn()
 */
util.getExpiresOn = (expiresIn) => {
  const date = new Date(); // 过期时间设为当前时间
  const expiresOn = date.getTime(); // 转化为时间戳毫秒数
  return expiresOn + 1000 * expiresIn;
};

/**
 * isNull
 * @description 自动识别对象类型并判断是否为空
 * @param {Object} val 需要判断的对象
 * @return {Boolean}   返回 true 或 false
 * @example 调用示例
 *  let isNull = util.isNull(user)
 */
util.isNull = (val) => {
  if (val instanceof Array) {
    if (val.length === 0) return true;
  } else if (val instanceof Object) {
    if (JSON.stringify(val) === "{}") return true;
  } else {
    if (
      val === "null" ||
      val === null ||
      val === "undefined" ||
      val === undefined ||
      val === ""
    ) {
      return true;
    }
    return false;
  }
  return false;
};

/**
 * looseEqual
 * @description 判断值是否相等(宽松)
 * @param {Objec}  value1
 * @param {Objec}  value2
 * @return {Boolean}
 */
util.looseEqual = (a, b) => {
  const isObjectA = util.isObject(a);
  const isObjectB = util.isObject(b);
  if (isObjectA && isObjectB) {
    return JSON.stringify(a) === JSON.stringify(b);
  } else if (!isObjectA && !isObjectB) {
    return String(a) === String(b);
  } else {
    return false;
  }
};

/**
 * isObject
 * @description 判断是否是对象
 * @param { Object}  obj
 * @return {Boolean}
 */
util.isObject = (obj) => {
  return Object.prototype.toString.call(obj) === "[object Object]";
};

/**
 * arrayEquals
 * @description 判断数组是否相等
 * @param {Array}    arrayA
 * @param {Array}    arrayB
 * @return {Boolean}
 */
util.arrayEquals = (arrayA, arrayB) => {
  arrayA = arrayA || [];
  arrayB = arrayB || [];
  if (arrayA.length !== arrayB.length) {
    return false;
  }
  for (let i = 0; i < arrayA.length; i++) {
    if (!util.looseEqual(arrayA[i], arrayB[i])) {
      return false;
    }
  }
  return true;
};

/**
 * isEqual
 * @description 判断值是否相等
 * @param {Objec}  value1
 * @param {Objec}  value2
 * @return {Boolean}
 */
util.isEqual = (value1, value2) => {
  if (Array.isArray(value1) && Array.isArray(value2)) {
    return util.arrayEquals(value1, value2);
  }
  return util.looseEqual(value1, value2);
};

/**
 * title
 * @description 从环境变量中获取并设置标题
 * @param {String} title 标题
 */
util.title = function (titleText) {
  const processTitle = process.env.VUE_APP_TITLE || "ACIA";
  window.document.title = `${processTitle}${
    titleText ? ` | ${titleText}` : ""
  }`;
};

/**
 * randomLenNum
 * @description 根据长度及日期生成随机数字,系统登录模块使用
 * @param {Int}     随机数长度
 * @param {Date}    日期
 * @return {String} 随机数
 */
util.randomLenNum = (len, date) => {
  let random = "";
  random = Math.ceil(Math.random() * 10000000000000)
    .toString()
    .substr(0, typeof len === "number" ? len : 4);
  if (date) random = random + Date.now();
  return random;
};

/**
 * toCamel
 * @description  下划线命名转换驼峰命名
 * @param {String}  name
 * @return {String} 驼峰命名
 * @example 调用示例
 *  let dictLabel = util.toCamel(name)
 */
util.toCamel = function (name) {
  return name.replace(/_(\w)/g, function (all, letter) {
    return letter.toUpperCase();
  });
};

/**
 * pathToCamel
 * @description  路由转换转换驼峰命名
 * @param {String}  name
 * @return {String} 驼峰命名
 * @example 调用示例
 *  let dictLabel = util.toCamel(name)
 */
util.pathToCamel = function (name) {
  name = name.replaceAll("/", "_");
  return util.toCamel(name);
};

/**
 * toUnderLine
 * @description  驼峰命名转换下划线命名
 * @param {String}  name
 * @return {String} 下划线命名
 * @example 调用示例
 *  let dictLabel = util.toUnderLine(name)
 */
util.toUnderLine = function (name) {
  return name.replace(/([A-Z])/g, "_$1").toLowerCase();
};

/**
 * open
 * @description 根据 url 自动生成 <a> 标签并模拟点击打开页面
 * @param {String} url 地址
 */
util.open = function (url) {
  const a = document.createElement("a");
  a.setAttribute("href", url);
  a.setAttribute("target", "_blank");
  a.setAttribute("id", "d2admin-link-temp");
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(document.getElementById("d2admin-link-temp"));
};

/**
 * getDictLabel
 * @description  根据类别及值从系统数据字典中获取中文名称
 * @param {String}  字典值
 * @param {String}  字典类别
 * @return {String} 字典的中文名称
 * @example 调用示例
 *  let dictLabel = util.getDictLabel(value,type)
 */
util.getDictLabel = (value, type) => {
  let label = "";
  if (!util.isNull(value)) {
    const dict = store.getters["dict/" + type];
    const underLineType = util.toUnderLine(type);
    // 数组获取 label
    if (Array.isArray(value)) {
      value.forEach((arrValue) => {
        dict.forEach((item) => {
          if (
            util.looseEqual(item.value, arrValue.toString()) &&
            util.looseEqual(item.type, underLineType)
          ) {
            label = label ? label + "," + item.label : item.label;
          }
        });
      });
    } else {
      // 字符串获取 label
      dict.forEach((item) => {
        if (
          util.looseEqual(item.value, value) &&
          util.looseEqual(item.type, underLineType)
        ) {
          label = item.label;
        }
      });
    }
  }
  return label;
};

/**
 * getPolygonCenter
 * @description  获取不规则面的中心点
 * @param {String}  面坐标点数组
 * @return {Array} 中心点坐标
 * @example 调用示例
 *  let dictLabel = util.getPolygonCenter([[x,y],[x,y],[x,y]])
 */
util.getPolygonCenter = (pointList) => {
  let area = 0;
  let x = 0;
  let y = 0;
  for (let i = 1; i <= pointList.length; i++) {
    const lat = pointList[i % pointList.length].lat;
    const lng = pointList[i % pointList.length].lng;
    const nextLat = pointList[i - 1].lat;
    const nextLng = pointList[i - 1].lng;
    const temp = (lat * nextLng - lng * nextLat) / 2;
    area += temp;
    x += (temp * (lat + nextLat)) / 3;
    y += (temp * (lng + nextLng)) / 3;
  }
  x = x / area;
  y = y / area;
  const params = {
    lat: x,
    lng: y,
  };
  return params;
};

util.initday = (date) => {
  dayjs.updateLocale("en", {
    relativeTime: {
      future: "in%s",
      past: "%s前",
      s: "秒",
      m: "1分钟",
      mm: "%d分钟",
      h: "1小时",
      hh: "%d小时",
      d: "一天",
      dd: "%d天",
      M: "一个月",
      MM: "%d月",
      y: "一年",
      yy: "%d年",
    },
  });
  const pushDate = dayjs(date).fromNow();
  return pushDate;
};

/**
 * getPolygonCenter
 * @description  控制输入值保留2位小数
 * @param {String}  输入值
 * @param {String}  保留的小数个数
 * @return {String} 处理以后的返回值
 * @example 调用示例
 *  let dictLabel = util.oninput(num, limit)
 */

util.oninput = (num, limit) => {
  let str = num.toString();
  const len1 = str.substr(0, 1);
  const len2 = str.substr(1, 1);
  // 如果第一位是0，第二位不是点，就用数字把点替换掉
  if (str.length > 1 && len1 === 0 && len2 !== ".") {
    str = str.substr(1, 1);
  }
  // 第一位不能是.
  if (len1 === ".") {
    str = "";
  }
  // 限制只能输入一个小数点
  if (str.indexOf(".") !== -1) {
    const str_ = str.substr(str.indexOf(".") + 1);
    if (str_.indexOf(".") !== -1) {
      str = str.substr(0, str.indexOf(".") + str_.indexOf(".") + 1);
    }
  }
  // 正则替换
  str = str.replace(/[^\d.\d]+/g, ""); // 保留数字和小数点
  if (limit / 1 === 1) {
    str = str.replace(/^\D*([0-9]\d*\.?\d{0,1})?.*$/, "$1"); // 小数点后只能输 1 位
  } else {
    str = str.replace(/^\D*([0-9]\d*\.?\d{0,2})?.*$/, "$1"); // 小数点后只能输 2 位
  }
  return str;
};

/**
 * getPolygonCenter
 * @description  控制输入值保留2位小数
 * @param {String}  输入值
 * @param {String}  保留的小数个数
 * @param {String}  输入的最大值
 * @return {String} 处理以后的返回值
 * @example 调用示例
 *  let dictLabel = util.oninput(num, limit)
 */

util.oninput = (num, limit, max) => {
  let str = num.toString();
  const len1 = str.substr(0, 1);
  const len2 = str.substr(1, 1);
  // 如果第一位是0，第二位不是点，就用数字把点替换掉
  if (str.length > 1 && len1 === 0 && len2 !== ".") {
    str = str.substr(1, 1);
  }
  // 第一位不能是.
  if (len1 === ".") {
    str = "";
  }
  // 限制只能输入一个小数点
  if (str.indexOf(".") !== -1) {
    const str_ = str.substr(str.indexOf(".") + 1);
    if (str_.indexOf(".") !== -1) {
      str = str.substr(0, str.indexOf(".") + str_.indexOf(".") + 1);
    }
  }
  // 正则替换
  str = str.replace(/[^\d.\d]+/g, ""); // 保留数字和小数点
  if (limit / 1 === 1) {
    str = str.replace(/^\D*([0-9]\d*\.?\d{0,1})?.*$/, "$1"); // 小数点后只能输 1 位
  } else {
    str = str.replace(/^\D*([0-9]\d*\.?\d{0,2})?.*$/, "$1"); // 小数点后只能输 2 位
  }

  // 控制值大于0小于特定值
  if (parseFloat(str) < 0) {
    str = 0;
  } else if (parseFloat(str) > max) {
    str = max;
  }
  return str;
};

util.unitPrice = (val) => {
  const regex = /\d{1,3}(?=(\d{3})+(\.|$))/g; // 替换规则
  val = String(Math.round(val * Math.pow(10, 2))); // 乘100 四舍五入
  let integer = val.substr(0, val.length - 2).replace(regex, "$&,"); // 最后两位前的为整数
  let decimal = val.substr(val.length - 2); // 最后两位为小数
  if (decimal == 0) {
    decimal = "00";
  }
  const result = `${integer || 0}.${decimal}`;
  return result;
};

/**
 * 二代身份证严格校验
 * @param idCard 长度须填写18位长度
 * @return Boolean 返回是否校验成功
 */
util.checkIdCard = (idCard) => {
  if (idCard.length !== 18) {
    return false;
  }
  const regionCode = idCard.substring(0, 6);
  //
  // const regionFlag = areanumber.findIndex(item => {
  //   return item.areanumber === regionCode
  // })
  if (!area[regionCode]) {
    return false;
  }
  console.log(regionCode, "regionCode");
  const birthDay = idCard.substring(6, 14);
  console.log(birthDay, "birthDay");
  const regx =
    /^((?:19[2-9]\d{1})|(?:20(?:(?:0[0-9])|(?:1[0-8]))))((?:0?[1-9])|(?:1[0-2]))((?:0?[1-9])|(?:[1-2][0-9])|30|31)$/;
  if (!regx.test(birthDay)) {
    return false;
  }
  const code = idCard.substring(14, 17);
  console.log(code, "code");
  if (isNaN(code * 1)) {
    return false;
  }

  const check = idCard.substring(17);
  const checkArr = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2];
  let res = 0;
  for (let i = 0; i < idCard.length - 1; i++) {
    res += idCard[i] * checkArr[i];
  }
  let res2 = res % 11;
  console.log(check, res2);
  const mapResult = [1, 0, "X", 9, 8, 7, 6, 5, 4, 3, 2];
  if (check != mapResult[res2]) {
    return false;
  }
  return true;
};

util.checkCode = (code) => {
  if (code.length !== 18) {
    return false;
  }
  const str1 = code.substring(0, 1);
  const str2 = code.substring(1, 2);
  const str3 = code.substring(2, 8);
  const str4 = code.substring(8, 17);
  const str5 = code.substring(17);
  console.log(str1, "-", str2, "-", str3, "-", str4, "-", str5);

  // 第1位：登记管理部门代码，
  // 使用阿拉伯数字或英文字母表示。分为1机构编制；2外交；3司法行政；4文化；5民政；6旅游；7宗教；8工会；9市场监管；
  // A中央军委改革和编制办公室；N农业；Y其他。
  // 第3-8位：登记管理机关行政区划码，使用阿拉伯数字表示。（参照《中华人民共和国行政区划代码》〔GB/T 2260〕）。
  // 第9-17位：主体标识码（组织机构代码），使用阿拉伯数字或英文字母表示。（参照《全国组织机构代码编制规则》〔GB 11714〕）。
  // 第18位：校验码，使用阿拉伯数字或英文字母表示。
  const firstArr = {
    1: ["1", "2", "3", "9"],
    2: ["1", "9"],
    3: ["1", "2", "3", "4", "5", "9"],
    4: ["1", "9"],
    5: ["1", "2", "3", "9"],
    6: ["1", "2", "9"],
    7: ["1", "2", "9"],
    8: ["1", "9"],
    9: ["1", "2", "3"],
    A: ["1", "9"],
    N: ["1", "2", "3", "9"],
    Y: ["1"],
  };

  // 首位校验
  if (!firstArr[str1]) {
    return false;
  }
  // 第二位校验
  let flag2 = firstArr[str1].findIndex((item) => item === str2);
  if (flag2 == -1) {
    return false;
  }
  // 地区码校验
  if (!area[str3]) {
    return false;
  }

  // 组织机构编码字母或数字位校验
  const regx = /[0-9a-zA-Z]+$/;
  if (!regx.test(str4)) {
    return false;
  }
  console.log(charToNum(str4), "str4");

  if (charToNum(str5) == -1) {
    return false;
  }
  console.log(charToNum(str5), "str5");
  let flag3 = checkCodeLast(code.substring(0, 17), str5);
  return flag3;
};

// 字符转相应字符代码
const charToNum = (c) => {
  switch (c) {
    case "0":
      return 0;
    case "1":
      return 1;
    case "2":
      return 2;
    case "3":
      return 3;
    case "4":
      return 4;
    case "5":
      return 5;
    case "6":
      return 6;
    case "7":
      return 7;
    case "8":
      return 8;
    case "9":
      return 9;
    case "A":
      return 10;
    case "B":
      return 11;
    case "C":
      return 12;
    case "D":
      return 13;
    case "E":
      return 14;
    case "F":
      return 15;
    case "G":
      return 16;
    case "H":
      return 17;
    case "J":
      return 18;
    case "K":
      return 19;
    case "L":
      return 20;
    case "M":
      return 21;
    case "N":
      return 22;
    case "P":
      return 23;
    case "Q":
      return 24;
    case "R":
      return 25;
    case "T":
      return 26;
    case "U":
      return 27;
    case "W":
      return 28;
    case "X":
      return 29;
    case "Y":
      return 30;
    default:
      return -1;
  }
};

//校验统一社会信用代码最后一位
const checkCodeLast = (str, lastStr) => {
  console.log(str);
  let Weight = [1, 3, 9, 27, 19, 26, 16, 17, 20, 29, 25, 13, 8, 24, 10, 30, 28]; //用于存放权值
  let index = 0; //用于计算当前判断的统一社会信用代码位数
  let testc; //用于存放当前位的统一社会信用代码
  let tempSum = 0; //用于存放代码字符和加权因子乘积之和
  let tempNum = 0;
  for (index = 0; index <= 16; index++) {
    testc = str[index];
    tempNum = charToNum(testc);

    if (tempNum != -1) {
      tempSum += Weight[index] * tempNum;
    }
  }

  tempNum = 31 - (tempSum % 31);
  if (tempNum == 31) {
    tempNum = 0;
  }

  //按照GB/T 17710标准对统一社会信用代码前17位计算校验码，并与第18位校验位进行比对
  if (charToNum(lastStr) == tempNum) {
    return true;
  } else {
    return false;
  }
};
export default util;
