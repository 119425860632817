<!--
 * @Description: 前台页面布局
 * @Author: Pancras
 * @Date: 2022-06-27 12:21:45
 * @LastEditors: yjk
 * @LastEditTime: 2023-05-16 13:38:29
-->
<template>
  <div>
    <!-- Header 开始 -->
    <header class="header" style="background: #fff">
      <!-- TopBar 开始 -->
      <div class="top-bar">
        <div flex="box:last" class="main">
          <div>
            <span style="font-size: 16px"
              >您好，欢迎来到新疆准噶尔棉花交易中心！</span
            >
            <template v-if="info && info.username">
              <span class="tl-ml" style="font-size: 16px">{{
                info.name ? info.name : info.username
              }}</span>
              <span class="tl-ml" style="font-size: 16px">|</span>
              <router-link class="login tl-ml tl-mr-10" to="/uc/home"
                >用户中心
              </router-link>
              <span class="tl-ml" style="font-size: 16px">|</span>
              <el-link
                type="primary"
                @click="handleLogout"
                class="login tl-ml tl-mr-10"
                ><span style="font-size: 16px">注销</span>
              </el-link>
            </template>
          </div>
          <div>
            <!-- <a href="javascript:void(0);"
               class="tl-ml-10 tl-mr-10"
               @click="setHome(this);">买家中心</a>

            <a href="javascript:void(0);"
               class="tl-ml-10 tl-mr-10"
               @click="addFavorite()">卖家中心</a>

            <a href="javascript:void(0);"
               style="color:#717272FF"
               class="tl-ml-10 tl-mr-10"
               @click="addFavorite()">加工企业</a> -->
            <a
              href="javascript:void(0);"
              style="color: #717272ff; font-size: 16px"
              class="tl-ml-10 tl-mr-10"
              @click="addFavorite()"
              >客服热线：0994 5823 696</a
            >
          </div>
        </div>
      </div>
      <!-- TopBar 结束 -->
      <div class="section">
        <div flex="box:mean main:justify cross:center" class="section-1">
          <div
            class="logo"
            flex="main:left cross:center"
            style="cursor: pointer"
            @click="toIndex"
          >
            <img src="@/assets/login/logo.png" />
            <div class="txt">新疆准噶尔棉花交易中心</div>
          </div>
          <div flex="main:right" class="tl-ml-40 search">
            <div>
              <search-item></search-item>
            </div>
            <div class="tl-ml">
              <el-badge
                :value="shoppingCarNum"
                :hidden="shoppingCarNum === 0"
                class="item"
              >
                <div
                  class="gwcBtn"
                  flex="main:center cross:center"
                  @click="toShoppingCar"
                >
                  <img src="@/assets/login/gwc.png" />
                  <span>我的购物车</span>
                </div>
              </el-badge>
            </div>
          </div>
        </div>
        <nav class="section-2">
          <menu-item></menu-item>
        </nav>
      </div>
    </header>
    <!-- Header 结束 -->
    <main class="main" style="background-color: #fff">
      <router-view></router-view>
      <!-- <tool-box-item></tool-box-item> -->
    </main>
    <footer class="footer">
      <footer-item></footer-item>
    </footer>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { _getGoodsCartsList } from "@api/product";
import setting from "@/setting";
import FooterItem from "./components/FooterItem"; // 底部
import SearchItem from "./components/SearchItem"; // 搜索
import MenuItem from "./components/MenuItem"; // 菜单
import util from "@/libs/util.js";
// import ToolBoxItem from './components/ToolBoxItem' // 页面右边二维码小工具

export default {
  name: "FrontLayout",
  components: {
    FooterItem,
    SearchItem,
    MenuItem,
    // ToolBoxItem
  },
  data() {
    return {
      setting,
      shoppingCarNum: 0,
      form: {
        current: 1, // 当前页面数
        size: 10, // 当前页面展示数值
      },
    };
  },
  mounted() {
    // 获取 Token
    const token = util.getToken();
    if (token && token !== "undefined") {
      this.getGoodsCartList();
    }
    // this.$bus.$off("shoppingCarChange");
    this.$bus.$on("shoppingCarChange", (data) => {
      if (data) {
        this.getGoodsCartList();
      }
    });
  },
  computed: {
    // 获取网站信息
    ...mapState("setting", ["siteInfo"]),
    // 获取当前登陆用户信息
    ...mapState("user", ["info"]),
  },
  methods: {
    ...mapActions("account", ["logout"]),

    /**
     * @name: handleLogin
     * @description: 登录
     */
    handleLogin() {
      this.$router.push("/login");
    },
    /**
     * 注册
     */
    handleToReg() {
      this.$router.push("/login?to=reg");
    },

    /**
     * @name: handleLogout
     * @description: 登出
     */
    handleLogout() {
      this.logout({
        vm: this,
        confirm: true,
      });
    },
    // 获取购物车列表
    getGoodsCartList() {
      _getGoodsCartsList(this.form)
        .then((res) => {
          this.shoppingCarNum = res.total;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 加入收藏
    addFavorite() {
      const title = this.siteInfo.name;
      const url = window.location.href;
      try {
        window.external.addFavorite(url, title);
      } catch (e) {
        try {
          window.sidebar.addPanel(title, url, "");
        } catch (e) {
          alert(
            "抱歉，您所使用的浏览器无法完成此操作。\n\n加入收藏失败，请进入新网站后使用Ctrl+D进行添加"
          );
        }
      }
    },
    // 设置首页
    setHome(obj) {
      const url = window.location.origin;
      try {
        obj.style.behavior = "url(#default#homepage)";
        obj.setHomePage(url);
      } catch (e) {
        if (window.netscape) {
          try {
            // eslint-disable-next-line no-undef
            netscape.security.PrivilegeManager.enablePrivilege(
              "UniversalXPConnect"
            );
          } catch (e) {
            alert(
              "抱歉，此操作被浏览器拒绝！\n\n请在浏览器地址栏输入“about:config”并回车然后将[signed.applets.codebase_principal_support]设置为'true'"
            );
          }
        } else {
          alert(
            "抱歉，您所使用的浏览器无法完成此操作。\n\n您需要手动将【" +
              url +
              "】设置为首页。"
          );
        }
      }
    },
    toShoppingCar() {
      this.$router.push({
        path: "/product/:index",
        name: "product",
        params: { index: 1 },
      });
    },
    toIndex() {
      this.$router.push({
        path: "/index",
      });
    },
  },
};
</script>

<style lang="scss">
// 注册主题
@import "~@/assets/style/layout/front.scss";
</style>

<style>
.username:hover {
  color: #169d58;
}
</style>
