/*
 * @Description: Session 工具类
 * @Author: Pancras
 * @Date: 2022-06-20 12:27:42
 * @LastEditors: Pancras
 * @LastEditTime: 2022-06-27 13:08:00
 */
const sessions = {}

// 获取 SessionStorage 对象
const SessionStorage = window.sessionStorage

/**
 * @description 存储 session 值
 * @param {String} name session name
 * @param {String} value session value
 */
sessions.set = function (name = 'default', value = '') {
  SessionStorage.setItem(name, value)
}

/**
 * @description 拿到 session 值
 * @param {String} name session name
 */
sessions.get = function (name = 'default') {
  return SessionStorage.getItem(name)
}

/**
 * @description 删除 session
 * @param {String} name session name
 */
sessions.remove = function (name = 'default') {
  return SessionStorage.removeItem(name)
}

/**
 * 清空全部localStorage
 */
sessions.clear = function () {
  SessionStorage.clear()
}

export default sessions
