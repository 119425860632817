<!--
 * @Description:搜索组件
 * @Author: Pancras
 * @Date: 2022-06-27 12:21:45
 * @LastEditors: szw
 * @LastEditTime: 2022-11-15 11:14:00
-->
<template>
  <el-form style="width:650px"
           :model="form">
    <el-input v-model="form.value"
              placeholder="请输入批号进行搜索"
              @keyup.enter.native="searchList"
              class="input-with-select">
      <i slot="prefix"
         style="color:#169D58;font-weight: bold;font-size:16px"
         class="el-input__icon el-icon-search"></i>
      <el-option v-for="item in options"
                 :key="item.value"
                 :label="item.label"
                 :value="item.value">
      </el-option>
      <el-button slot="append"
                 style="background-color:#fff;color:#000;"
                 @click="searchList">搜索</el-button>
    </el-input>
  </el-form>
</template>

<script>
export default {
  name: 'SearchItem',
  components: {},
  data () {
    return {
      options: [{
        value: '1',
        label: '批号'
      }, {
        value: '2',
        label: '储运站'
      }, {
        value: '3',
        label: '轧花厂'
      }],
      form: {
        searchType: '1',
        value: ''
      }
    }
  },
  methods: {
    searchList () {
      if (this.form.value !== '') {
        if (this.$router.history.current.fullPath === '/product/0') {
          this.$bus.$emit('searchBatchId', this.form.value)
        } else {
          this.$router.push({
            path: '/product/:index',
            name: 'product',
            params: { index: 0, batchId: this.form.value }
          })
        }
      } else {
        this.$message({
          message: '请输入后再查询',
          type: 'warning'
        })
      }
    }
  }
}

</script>
<style lang="scss">
.el-button {
  border: 1px solid $color-primary;
  background-color: #fff;
  border-radius: 0;
  color: #fff;
  margin: 0 auto;
}

li.el-select-dropdown__item.selected {
  color: $color-primary;
}

.input-with-select {
  // width: 480px;

  .el-input-group__prepend {
    width: 45px;
    border: 1px solid $color-primary;
    background-color: #fff;
    border-radius: 2px;
    color: #fff;
  }

  .el-input__inner {
    border: 1px solid $color-primary;
    border-right: 0px;

    &:focus {
      border-color: $color-primary;
    }
  }

  .el-input-group__append {
    width: 50px;
    color: #fff;
    border-radius: 0px;
    border: 1px solid $color-primary;
    background-color: $color-primary;
    padding: 0;
  }
}
</style>
