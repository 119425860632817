/*
 * @Description: 商品超市
 * @Author: szw
 * @Date: 2022-10-11 11:17:48
 * @LastEditors: yjk
 * @LastEditTime: 2023-09-25 10:36:38
 */
import request from "@/libs/axios";

/**
 * 获取商品超市销售列表
 * @param {*} params
 * @returns
 */
export function _GetProductSaleList(params) {
  return request({
    url: "/tf-trading/tf-goods/shop/page",
    method: "get",
    params: { ...params },
  });
}

/**
 * 加入购物车
 * @param {*} params
 * @returns
 */
export function _BatchAddGoodsCart(params) {
  return request({
    url: "/tf-trading/tf-shopping-cart/add/goods",
    method: "post",
    data: params,
  });
}

/**
 * 获取购物车列表
 * @param {*} params
 * @returns
 */
export function _getGoodsCartsList(params) {
  return request({
    url: "/tf-trading/tf-shopping-cart/goods/page",
    method: "get",
    params: { ...params },
  });
}

/**
 * 购物车锁单
 * @param {*} params
 * @returns
 */
export function _LockOrderShoppingCar(params) {
  return request({
    url: "/tf-trading/tf-order/lock/order",
    method: "post",
    data: params,
  });
}

/**
 * 移除购物车订单
 * @param {*} params
 * @returns
 */
export function _DelOrderShoppingCar(params) {
  return request({
    url: "/tf-trading/tf-shopping-cart/remove/goods",
    method: "post",
    data: params,
  });
}

/**
 * 获取我的采购订单列表(买家)
 * @param {*} params
 * @returns
 */
export function _GetMyPurchaseOrderList(params) {
  return request({
    url: "/tf-trading/tf-order/fair/buy/order/page",
    method: "get",
    params: { ...params },
  });
}

/**
 * 获取我的采购订单列表(卖家)
 * @param {*} params
 * @returns
 */
export function _GetSellerPurchaseOrderList(params) {
  return request({
    url: "/tf-trading/tf-order/fair/sell/order/page",
    method: "get",
    params: { ...params },
  });
}

/**
 * 分页查询订单详情表
 * @param {*} params
 * @returns
 */
export function _GetOrderGoodsList(params) {
  return request({
    url: "/tf-trading/tf-order-detail/order/goods/info/page",
    method: "get",
    params: { ...params },
  });
}

/**
 * 买家取消订单
 * @param {*} params
 * @returns
 */
export function _BuyerCancelOrder(params) {
  return request({
    url: "/tf-trading/tf-order/buy/cancel/order",
    method: "post",
    data: params,
  });
}

/**
 * 卖家取消订单
 * @param {*} params
 * @returns
 */
export function _SellerCancelOrder(params) {
  return request({
    url: "/tf-trading/tf-order/sell/cancel/order",
    method: "post",
    data: params,
  });
}

/**
 * 查询取消原因
 * @param {*} params
 * @returns
 */
export function _GetOrderCancelReason(params) {
  return request({
    url: "/tf-trading/tf-cancel-order/cancel/order/info",
    method: "get",
    params: { ...params },
  });
}

/**
 * 分页查询竞价出价记录表
 * @param {*} params
 * @returns
 */
export function _GetPriceLogPage(params) {
  return request({
    url: "/tf-trading/tf-fair-price-log/page",
    method: "get",
    params: { ...params },
  });
}

/**
 * 获取竞价确认书
 * @param {*} params
 * @returns
 */
export function _GetOrderBiddingConfirm(params) {
  return request({
    url: "/tf-trading/tf-order/confirm/" + params.orderId,
    method: "get",
  });
}
