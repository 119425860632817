/**
 * 自定义过滤器
 * 过滤器说明:
 *   1,Vue2.x废弃了内置过滤器，允许自定义过滤器
 *   2,过滤器可以用在两个地方：双花括号插值和 v-bind 表达式 (后者从 2.1.0+ 开始支持)
 *   3,过滤器应该被添加在 JavaScript 表达式的尾部，由“管道”符号指示
 * 使用示例:
 *   1,双花括号插值: {{ message | capitalize }}
 *   2,v-bind 绑定: <div v-bind:id="rawId | formatId"></div>
 */
import util from '@/libs/util' // 工具类
import dayjs from 'dayjs' // 日期工具类
import 'dayjs/locale/zh-cn' // 中文包
import relativeTime from 'dayjs/plugin/relativeTime' // 相对时间处理

// 配置使用处理相对时间的插件
dayjs.extend(relativeTime)

// 配置使用中文语言包
dayjs.locale('zh-cn')

//  时间戳格式滤器
const dateFilter = (value, format) => {
  if (util.isNull(format)) {
    format = 'YYYY-MM-DD'
  }
  return dayjs(value).format(format)
}

//  相对时间处理
const dateRelativeFilter = (value) => {
  return dayjs(value).fromNow()
}

/**
 * 获取字典名称
 * @description 根据字典类型及字典值获取字典名称
 * @param {Object} value 字典值
 * @param {String} type  字典类型
 */
const dictFilter = (value, type) => {
  return util.getDictLabel(value, type)
}

/**
 * 获取字典项列表
 * @description 根据字典类型获取字典项列表
 * @param {Object} value 字典值
 * @param {String} type  字典类型
 */
const dictListFilter = (type) => {
  return util.getDictList(type)
}

/**
 * 金钱单位置换  2999 --> 2,999.00
 */
const unitPrice = (val, unit, location) => {
  return util.unitPrice(val, unit, location)
}

/**
 * 订单状态列表
 */
const orderStatusList = (val) => {
  return util.orderStatusList(val)
}

/**
 * 清除逗号
 *
 */
const clearStrComma = (str) => {
  str = str.replace(/,/g, '') // 取消字符串中出现的所有逗号
  return str
}
/**
 * 13888888888 -> 138****8888
 * @param mobile
 * @returns {*}
 */
const secrecyMobile = (mobile) => {
  mobile = String(mobile)
  if (!/\d{11}/.test(mobile)) {
    return mobile
  }
  return mobile.replace(/(\d{3})(\d{4})(\d{4})/, '$1****$3')
}

export default {
  dateFilter,
  dateRelativeFilter,
  dictFilter,
  dictListFilter,
  unitPrice,
  orderStatusList,
  clearStrComma,
  secrecyMobile
}
