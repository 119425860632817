<!--
 * @Description: 用户中心菜单组件
 * @Author: Pancras
 * @Date: 2022-07-04 16:09:02
 * @LastEditors: szw
 * @LastEditTime: 2022-11-10 08:43:43
-->
<template>
  <div class="menu-container">
    <!-- <div class="title-bar"
         flex="box:justify main:center cross:center">
      <div class="l-icon">
        <img src="~@/assets/uc/user.png"
             alt="">
      </div>
      <div class="title">
        个人中心
      </div>
      <div class="r-icon">
        <router-link to="/uc/message">
          <el-badge :value="unreadNum"
                    class="item"
                    type="success">
            <img src="~@/assets/uc/email.png"
                 alt="">
          </el-badge>
        </router-link>
      </div>
    </div> -->
    <div class="nav-menu">
      <el-menu
        default-active="/uc/home"
        class="el-menu-vertical-demo"
        @select="handleSelect">
        <el-menu-item index="/uc/home">
          <i class="el-icon-s-home"></i>
          <span>首页</span>
        </el-menu-item>
        <el-submenu index="2">
          <template slot="title">
            <i class="el-icon-user"></i>
            <span>账户管理</span>
          </template>
          <el-menu-item index="/uc/authenticatedList">用户认证</el-menu-item>
          <el-menu-item index="/uc/safe">修改密码</el-menu-item>
          <el-menu-item index="/uc/mobile">手机换绑</el-menu-item>
        </el-submenu>
        <el-submenu index="3">
          <template slot="title">
            <i class="el-icon-coin"></i>
            <span>保证金</span>
          </template>
          <el-menu-item index="/uc/marginManage">保证金管理</el-menu-item>
        </el-submenu>
        <el-submenu index="4" v-if="userApproveInfo.isAuthentication === '2' || userApproveInfo.isAuthentication === '3'">
          <template slot="title">
            <i class="el-icon-document"></i>
            <span>我的销售</span>
          </template>
          <el-menu-item index="/uc/releaseGoods">商品管理</el-menu-item>
          <el-menu-item index="/uc/salesOrder">超市订单</el-menu-item>
          <el-menu-item index="/uc/auctionSaleFair">我的交易会</el-menu-item>
          <el-menu-item index="/uc/auctionApply">交易会报名</el-menu-item>
          <el-menu-item index="/uc/myAuctionApply">竞价报名商品</el-menu-item>
          <el-menu-item index="/uc/auctionSaleOrder">竞价订单</el-menu-item>
          <el-menu-item index="/uc/basicTradeSeller">点价意向</el-menu-item>
        </el-submenu>
        <el-submenu index="5" v-if="userApproveInfo.isAuthentication === '1' || userApproveInfo.isAuthentication === '3'">
          <template slot="title">
            <i class="el-icon-shopping-cart-2"></i>
            <span>我的采购</span>
          </template>
          <el-menu-item index="/uc/myPurchaseList">超市订单</el-menu-item>
          <el-menu-item index="/uc/auctionPurchaseOrder">竞价订单</el-menu-item>
          <el-menu-item index="/uc/basicTradeBuyer">点价记录</el-menu-item>
        </el-submenu>
        <el-submenu index="6">
          <template slot="title">
            <i class="el-icon-setting"></i>
            <span>认证申报</span>
          </template>
          <el-menu-item index="/uc/apply?id=5">CCIA认证</el-menu-item>
          <el-menu-item index="/uc/apply?id=13">本地认证</el-menu-item>
          <el-menu-item index="/uc/authentication">其他认证</el-menu-item>
          <el-menu-item index="/uc/history">申请记录</el-menu-item>
        </el-submenu>
      </el-menu>
    </div>
  </div>
</template>

<script>
import { _GetUserApprove } from '@/api/user'
import { _GetIndexUnreadMessage } from '@api/userIndex'
export default {
  name: 'MenuItem',
  data () {
    return {
      userApproveInfo: {}, // 用户认证信息
      unreadNum: 0,
      timer: null
    }
  },
  created () {
    this.getUserApprove()
  },
  // mounted () {
  //   const that = this
  //   this.getUnreadMessage()
  //   this.timer = setInterval(() => {
  //     that.getUnreadMessage()
  //   }, 5000)
  // },
  // destroyed () {
  //   clearInterval(this.timer)
  // },
  methods: {
    // 获取用户认证状态
    getUserApprove () {
      _GetUserApprove().then(res => {
        this.userApproveInfo = res
      }).catch(err => {
        console.log(err)
      })
    },
    // 获取未读信息数量
    getUnreadMessage () {
      _GetIndexUnreadMessage().then(res => {
        this.unreadNum = res
      }).catch(err => {
        console.log(err)
      })
    },
    // 菜单选择跳转
    handleSelect (key, keyPath) {
      this.$router.push({
        path: key
      })
    }
  }
}
</script>
<style lang="scss">
.nav-menu{
  .el-menu{
    border: none;
  }
}
</style>
<style lang="scss" scoped>
.menu-container {
  background-color: #fff;
  min-height: 905px;

  .l-icon {
    width: 20px;
    height: 20px;
  }

  .title-bar {
    padding: 20px 35px;
    margin: 0 auto;
    border-bottom: 1px solid $color-primary;

    .title {
      width: 80px;
      font-size: 20px;
      font-weight: 600;
      color: #031a12;
      margin-left: 10px;
    }

    .r-icon {
      margin-top: 10px;
    }
  }

  .nav-menu{
    width: 200px;
  }

  // .nav-container {
    // .nav {
    //   li {
    //     position: relative;
    //     list-style: none;
    //     height: 24px;
    //     font-size: 14px;
    //     font-weight: 400;
    //     color: #323333;
    //     line-height: 24px;

    //     a {
    //       color: #323333;
    //     }

    //     &:first-child {
    //       font-size: 16px;
    //       font-weight: 600;
    //       color: #031a12;
    //       line-height: 24px;

    //       &::before {
    //         content: "";
    //         position: absolute;
    //         top: 8px;
    //         left: -10px;
    //         width: 6px;
    //         height: 6px;
    //         background: $color-primary;
    //         border-radius: 50%;
    //       }
    //     }

    //     &:not(:first-child) {
    //       margin-left: 1em;
    //     }
    //   }
    // }
  // }
}
</style>
