<!--
 * @Description: 菜单组建
 * @Author: Pancras
 * @Date: 2022-06-27 12:21:45
 * @LastEditors: szw
 * @LastEditTime: 2022-11-18 11:39:50
-->
<template>
  <ul class="menu"
      flex="box:mean">
    <li class="disabled">
        <div class="icon"></div>
        <span>快速导航</span>
    </li>
    <li>
      <router-link to="/index"
                   active-class="active">首页</router-link>
    </li>
    <li>
      <router-link :to="{name:'product',params:{index:0}}"
                   active-class="active">商品超市</router-link>
    </li>
    <li>
      <router-link to="/auction"
                   active-class="active">竞价交易</router-link>
    </li>
    <li>
      <router-link to="/basicTrade"
                   active-class="active">基差交易</router-link>
    </li>
    <li>
      <router-link to="/tradeAuth"
                   active-class="active">交易认证</router-link>
    </li>
    <li>
      <router-link :to="{name:'richShop',params:{index:0}}"
                   active-class="active">企业旺铺</router-link>
    </li>
    <li>
      <!-- <router-link to=""
                   active-class="active">用户中心</router-link> -->
      <a href="https://scf.lztl.cn/" target="_blank">供应链金融</a>
    </li>
  </ul>
</template>

<script>

export default {
  name: 'MenuItem',
  components: {}
}
</script>
<style lang="scss" scoped>
.menu {
  @extend %wrapper;
  background: #ffffff;
  // margin-bottom: 20px;
  padding: 9px 0px 0px;
  // border-bottom: 2px solid #0f985e;
  .disabled{
    background: #0F985E;
    color: #ffffff;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 84px;
    height: 36px;
    font-size: 16px;
    margin-right: 20px;
    .icon{
      margin-top: 1px;
      margin-right: 10px;
      width: 16px;
      height: 3px;
      border-top: 8px double;//上边框
      border-bottom: 3px solid;//下边框
    }
  }

  .active {
    // color: $color-primary;
    border-bottom: 2px solid #169D58;
    // background-color: #169D58;
    color: #0F985E;
  }

  li {
    float: left;
    // border-right: 1px solid $color-border-3;
    height: 30px;

    a {
      display: block;
      height: 34px;
      line-height: 30px;
      text-align: center;
      font-size: 16px;
      font-weight: bold;
      color: rgb(0, 0, 0);
      font-weight: 500;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    a:hover {
      // color: $color-primary;
      cursor: pointer;
    }
  }

  li:last-child {
    border-right: 0px;
  }
}
</style>
