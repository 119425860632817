/*
 * @Description: Cookie 工具类
 * @Author: Pancras
 * @Date: 2022-06-20 12:27:42
 * @LastEditors: Pancras
 * @LastEditTime: 2022-07-04 17:55:37
 */
import Cookies from 'js-cookie'

const cookies = {}

/**
 * @description 存储 Cookie 值
 * @param {String} name Cookie name
 * @param {String} value Cookie value
 * @param {Object} setting Cookie setting
 */
cookies.set = function (name = 'default', value = '', cookieSetting = {}) {
  const currentCookieSetting = {
    expires: 1
  }
  Object.assign(currentCookieSetting, cookieSetting)
  Cookies.set(`${name}`, value, currentCookieSetting)
}

/**
 * @description 拿到 Cookie 值
 * @param {String} name cookie name
 */
cookies.get = function (name = 'default') {
  return Cookies.get(`${name}`)
}

/**
 * @description 拿到 Cookie 全部的值
 */
cookies.getAll = function () {
  return Cookies.get()
}

/**
 * @description 删除 Cookie
 * @param {String} name Cookie name
 */
cookies.remove = function (name = 'default') {
  return Cookies.remove(`${name}`)
}

export default cookies
