/*
 * @Description: 用户管理接口文件
 * @Author: Pancras
 * @Date: 2022-07-16 18:05:59
 * @LastEditors: szw
 * @LastEditTime: 2022-11-08 15:39:13
 */
import request from '@/libs/axios'

/**
 * _PutUserself
 * @description 修改个人信息
 * @param {Object} userSelfDto 数据传输对象
 */
export function _PutUserself (userSelfDto) {
  return request({
    url: '/admin/member/self',
    method: 'put',
    data: userSelfDto
  })
}

/**
 * _GetUserRoles
 * @description 获取用户角色列表
 */
export function _GetUserRoles (userSelfDto) {
  return request({
    url: '/admin/member/roles',
    method: 'get'
  })
}

/**
 * @name: _GetDeptTree
 * @description: 获取组织树形集合[全部]
 */
export function _GetDeptTree () {
  return request({
    url: '/admin/dept/tree',
    method: 'get'
  })
}

/**
 * @name: _GetDeptTree
 * @description: 查询当前登录用户认证
 */
export function _GetUserApprove () {
  return request({
    url: '/tf-trading/tf-member-authentication/status',
    method: 'get'
  })
}

/**
 * @name: _GetDeptTree
 * @description: 用户买卖方认证
 */
export function _UserApprove (params) {
  return request({
    url: '/tf-trading/tf-member-authentication',
    method: 'post',
    data: params
  })
}

/**
 * @name: _GetDeptTree
 * @description: 获取各个时间段成交金额
 */
export function _GetAmountInfo () {
  return request({
    url: '/tf-trading/tf-home-page/transaction/amount/info',
    method: 'get'
  })
}

/**
 * @name: _GetDeptTree
 * @description: 获取各个时间段成交金额
 */
export function _GetAmountList (params) {
  return request({
    url: '/tf-trading/tf-home-page/transaction/sell/info',
    method: 'get',
    params: { ...params }
  })
}

/**
 * @name: _GetDeptTree
 * @description: 获取销售占比统计
 */
export function _GetSaleRadio () {
  return request({
    url: '/tf-trading/tf-home-page/transaction/radio/info',
    method: 'get'
  })
}
