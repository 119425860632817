<!--
 * @Description: 页面底部组件
 * @Author: Pancras
 * @Date: 2022-06-27 12:21:45
 * @LastEditors: yjk
 * @LastEditTime: 2023-09-19 11:09:47
-->
<template>
  <div flex="dir:top">
    <!-- Footer顶部开始 -->
    <!-- <div class="section-top">
      <div class="content"
           flex="main:justify">
        <div flex="cross:center">
          <div class="icon">
            <img src="/images/source.png">
          </div>
          <div flex="dir:top main:center"
               class="tl-ml">
            <div class="title">源头采购</div>
            <div class="description">政府牵头 源头直采</div>
          </div>
        </div>
        <div flex="cross:center">
          <div class="icon">
            <img src="/images/authen.png">
          </div>
          <div flex="dir:top main:center"
               class="tl-ml">
            <div class="title">双重认证</div>
            <div class="description">CCIA认证 阿瓦提认证</div>
          </div>
        </div>
        <div flex="cross:center">
          <div class="icon">
            <img src="/images/trace.png">
          </div>
          <div flex="dir:top main:center"
               class="tl-ml">
            <div class="title">精准溯源</div>
            <div class="description">种植 加工 仓储全方位溯源</div>
          </div>
        </div>
      </div>
    </div> -->
    <!-- Footer 顶部结束 -->
    <!-- Footer 底部开始 -->
    <div class="section-bottom">
      <div class="content tl-mt-40" flex="box:justify">
        <div class="right tl-mt" flex="dir:top main:justify cross:left">
          <div class="phone">0994 5823 696</div>
          <div class="time">{{ setting.webSite.officeHours }}</div>
          <div class="desc">仅市话费</div>
          <div>
            <div class="contact-me-btn">联系我们</div>
          </div>
        </div>

        <div class="left" flex="box:mean">
          <div
            flex="dir:top main:center cross:center"
            style="text-align: center"
          >
            <h4>关于我们</h4>
            <ul class="list">
              <li>
                <a :href="`/information/details/221`">平台简介</a>
              </li>
              <li></li>
              <li></li>
              <li></li>
            </ul>
          </div>
          <!-- <div flex="dir:top main:center cross:center">
            <h4>棉花联盟</h4>
            <ul class="list">
              <li v-for="(i, index) in cotton"
                  :key="index"><a :href="`/information/details/${i.infoId}`">{{
                  i.title
                }}</a></li>
            </ul>
          </div> -->
          <div
            flex="dir:top main:center cross:center"
            style="text-align: center"
          >
            <h4>交易指南</h4>
            <ul class="list">
              <li>
                <a :href="`/information/details/222`">交易规则</a>
              </li>
              <li @click="toDetail('/uc/releaseGoods')">
                <a href="javascript:">发布资源</a>
              </li>
              <li @click="toDetail('/uc/salesOrder')">
                <a href="javascript:">订单管理</a>
              </li>
              <li></li>
            </ul>
          </div>
          <div
            flex="dir:top main:center cross:center"
            style="text-align: center"
          >
            <h4>常见问题</h4>
            <ul class="list">
              <!-- <li @click="toDetail('/uc/user')">
                <a href="javascript:">用户信息修改</a>
              </li> -->
              <li>
                <a :href="`/login`">密码找回</a>
              </li>
              <li @click="toApprove">
                <a href="javascript:">企业认证</a>
              </li>
              <li></li>
              <li></li>
            </ul>
          </div>
        </div>

        <div class="center" flex="box:center">
          <div flex="dir:top main:center cross:center">
            <img src="@/assets/auction/qrcode.jpg" />
            <div style="margin-top: 5px">
              {{ setting.webSite.wxQrCodeTitle }}
            </div>
          </div>
          <!-- <div flex="dir:top main:center cross:center"
               style="margin-left: 20px;">
            <img :src="setting.webSite.h5QrCodeUrl" />
            <div style="margin-top: 5px;">{{ setting.webSite.h5QrCodeTitle }}</div>
          </div> -->
        </div>
      </div>
      <div class="copyrightLine" />
      <div class="copyright tl-mt">
        Copyright ©{{ new Date().getFullYear()
        }}{{ siteInfo && siteInfo.name }}版权所有
      </div>
      <div class="copyright tl-mt-3">
        技术支持：新疆绿洲驼铃农业发展有限公司
        <!--{{siteInfo&&siteInfo.technicalSupport}} -->
      </div>
    </div>
    <!-- Footer 底部结束 -->
  </div>
</template>

<script>
import setting from "@/setting";
import { mapState } from "vuex";
import { _GetUserApprove } from "@/api/user";

export default {
  name: "FooterItem",
  computed: {
    // 获取网站信息
    ...mapState("setting", ["siteInfo"]),
    // 获取当前登陆用户信息
    ...mapState("user", ["info"]),
  },
  data() {
    return {
      setting,
      about: [
        {
          title: "关于我们",
          infoId: 77,
        },
        {
          title: "服务协议",
          infoId: 78,
        },
        {
          title: "隐私协议",
          infoId: 79,
        },
        {
          title: "规则说明",
          infoId: 80,
        },
      ],
      cotton: [
        {
          title: "联盟简介",
          infoId: 81,
        },
        {
          title: "组织机构",
          infoId: 82,
        },
        {
          title: "联盟章程",
          infoId: 83,
        },
        {
          title: "入驻联盟",
          infoId: 84,
        },
      ],
      trade: [
        {
          title: "商家入驻",
          infoId: 87,
        },
        {
          title: "仓单交易",
          infoId: 88,
        },
        {
          title: "交割指南",
          infoId: 89,
        },
        {
          title: "柜台办理",
          infoId: 90,
        },
      ],
      problem: [
        {
          title: "用户注册",
          infoId: 91,
        },
        {
          title: "密码找回",
          infoId: 92,
        },
        {
          title: "企业认证",
          infoId: 93,
        },
        {
          title: "个人认证",
          infoId: 94,
        },
      ],
      userApproveInfo: {}, // 用户认证信息
    };
  },
  mounted() {
    // this.getEstimate()
    // 已经登陆获取角色名称
    if (this.info && this.info.username) {
      this.login = true;
      // this.getUserRoles()
      this.getUserApprove();
    }
  },
  methods: {
    toDetail(path) {
      if (this.info && this.info.username) {
        // 已登录
        this.$router.push(path);
      } else {
        // 未登录
        this.$confirm("您尚未登录，是否前往登录页?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.$router.push("/login").catch(() => {});
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消",
            });
          });
      }
    },
    // 获取用户认证状态
    getUserApprove() {
      _GetUserApprove()
        .then((res) => {
          this.userApproveInfo = res;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 前往认证界面
    toApprove() {
      if (this.info && this.info.username) {
        // 已登录
        if (this.userApproveInfo.isAuthentication === "0") {
          this.$router.push({
            path: "/uc/userApprove/:type",
            name: "userApprove",
            params: { type: 0 },
          });
        } else if (this.userApproveInfo.isAuthentication === "1") {
          this.$router.push({
            path: "/uc/userApprove/:type",
            name: "userApprove",
            params: { type: 1 },
          });
        } else if (this.userApproveInfo.isAuthentication === "2") {
          this.$router.push({
            path: "/uc/userApprove/:type",
            name: "userApprove",
            params: { type: 0 },
          });
        } else if (this.userApproveInfo.isAuthentication === "3") {
          this.$router.push("/uc/authenticatedList");
        }
      } else {
        // 未登录
        this.$confirm("您尚未登录，是否前往登录页?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.$router.push("/login").catch(() => {});
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消",
            });
          });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
// Footer 顶部样式
.section-top {
  background-color: #dfece3;
  color: #fff;

  .content {
    @extend %wrapper;
    height: 130px;

    @media screen and (max-width: 1200px) {
      width: 95%;
    }

    > div:last-child {
      .icon {
        width: 30px;
        height: 30px;

        img {
          width: 30px;
          height: 30px;
        }
      }
    }

    .icon {
      width: 40px;
      height: 40px;

      img {
        width: 40px;
        height: 40px;
      }
    }

    .title {
      height: 24px;
      font-size: 16px;
      color: #031a12;
      font-weight: bold;
      line-height: 24px;
    }

    .description {
      height: 20px;
      font-size: 14px;
      color: #031a12;
      font-weight: 500;
      line-height: 20px;
    }
  }
}

// Footer 底部样式
.section-bottom {
  background-color: #009154;
  color: #fff;
  height: 400px;

  .content {
    height: 210px;
    @extend %wrapper;
    @media screen and (max-width: 1200px) {
      width: 95%;
    }
    .left {
      margin-left: 100px;
      margin-right: 100px;
      h4 {
        font-size: 16px;
      }
      ul {
        list-style: none;
        margin: 20px 0 0;
        padding: 0;
        font-size: 14px;

        li {
          color: rgba($color: #fff, $alpha: 0.7);
          width: 100px;
          height: 32px;
          line-height: 22px;

          a {
            color: rgba($color: #fff, $alpha: 0.7);
            cursor: pointer;

            &:active {
              color: $color-primary;
            }
          }
        }
      }
    }

    .center {
      font-size: 14px;
      img {
        width: 122px;
        height: 122px;
      }
    }

    .right {
      height: 160px;

      .phone {
        font-size: 20px;
        font-weight: bold;
      }

      .time {
        font-size: 14px;
        opacity: 0.8;
      }

      .desc {
        font-size: 14px;
        opacity: 0.8;
      }

      .contact-me-btn {
        background: transparent;
        border: 1px solid #fff;
        text-align: center;
        line-height: 36px;
        font-size: 14px;
        width: 120px;
        height: 36px;
        border-radius: 3px;
        opacity: 0.62;
        border: 1px solid rgba($color: #fff, $alpha: 1);

        &:hover {
          cursor: pointer;
        }
      }
    }
  }

  .copyrightLine {
    margin-top: 44px;
    width: 100%;
    opacity: 0.12;
    height: 1px;
    background: #fff;
    margin-bottom: 27px;
  }

  .copyright {
    opacity: 0.4;
    height: 22px;
    @extend %wrapper;
    font-size: 12px;
    text-align: center;
  }
}
</style>
