/*
 * @Description: 用一句话描述文件的作用
 * @Author: yjk
 * @Date: 2023-05-12 14:29:26
 * @LastEditors: yjk
 * @LastEditTime: 2023-05-12 14:35:25
 */
// 防抖
const debounce = function (fn, delay) {
  let timer = null;
  return function () {
    let content = this;
    let args = arguments;
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(() => {
      fn.apply(content, args);
    }, delay);
  };
};

export default debounce;
