/*
 * @Description: 系统配置文件
 * @Author: Pancras
 * @Date: 2019-09-29 10:44:30
 * @LastEditors: szw
 * @LastEditTime: 2023-01-12 11:17:05
 */
export default {
  webSite: {
    // title: '阿瓦提县长绒棉现货交易中心',
    logo: '',
    // copyright: 'Copyright ©2022 阿瓦提县长绒棉现货交易中心 版权所有',
    // suport: '新疆绿洲驼铃农业发展有限公司',
    // phone: '155 9976 2720',
    // email: 'MXBV@sina.cn',
    officeHours: '周一至周五 10:00-20:00',
    h5QrCodeTitle: '关注我们',
    h5QrCodeUrl: 'http://api.lztl.cn/admin/sys-file/lztl-mall/2022/5/38d2f35305aa4f45912c523db65c2344.png',
    wxQrCodeTitle: '公众号',
    wxQrCodeUrl: '@/assets/auction/qrcode.jpg',
    // wxQrCodeUrl: 'http://api.lztl.cn/admin/sys-file/lztl-mall/2022/5/71c4f8b278b742978806f283f2878103.png',
    appStewardTitle: '关注我们',
    appStewardQcode: 'http://api.lztl.cn/admin/sys-file/lztl-mall/2022/5/38d2f35305aa4f45912c523db65c2344.png',
    appLaddieTitle: '公众号',
    appLaddieQrCode: 'http://api.lztl.cn/admin/sys-file/lztl-mall/2022/5/71c4f8b278b742978806f283f2878103.png'
    // address: '新疆阿克苏地区阿瓦提县北京路555号'
  },
  // 服务器配置
  server: {
    // 定义 BASE_URL
    // baseUrl: 'http://192.168.31.26:9999',
    baseUrl: 'https://liushi.lztl.cn',
    // 加密用 key 应和服务器保持一致
    encryptionKey: 'asspasspasspassp',
    // 租户ID
    tenantId: '5',
    // 认证中心加密字符串，应和数据库配置的的客户端保持一致
    authorization: 'Basic YXdhdDphd2F0'
  }
}
