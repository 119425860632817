/*
 * @Description: 用一句话描述文件的作用
 * @Author: Pancras
 * @Date: 2022-06-20 12:27:42
 * @LastEditors: Pancras
 * @LastEditTime: 2022-07-27 12:34:00
 */
import Vue from 'vue'
import util from '@/libs/util.js'

/** 权限指令**/
const has = Vue.directive('has', {
  inserted: function (el, binding, vnode) {
    // 验证按钮权限
    if (!Vue.prototype.$_has(binding.value)) {
      el.parentNode.removeChild(el)
    }
  }
})
// 权限检查方法
Vue.prototype.$_has = function (value) {
  let isExist = false
  const permissions = util.cookies.get('permissions')
  if (permissions === undefined || permissions == null) {
    return false
  }
  if (permissions.indexOf(value) > -1) {
    isExist = true
  }
  return isExist
}

export { has }
