/*
 * @Description: Store：用户账户
 * @Author: Pancras
 * @Date: 2019-10-24 14:17:51
 * @LastEditors: yjk
 * @LastEditTime: 2023-10-09 10:48:31
 */
import { Message, MessageBox } from "element-ui";
import util from "@/libs/util.js";
import router from "@/router";
import {
  _LoginByAccount,
  _Logout,
  _GetUserInfo,
  _RefreshToken,
} from "@api/sys.login";

export default {
  namespaced: true,
  actions: {
    /**
     * @description  使用用户账户登录
     * @param {Object} context
     * @param {Object} payload username {String} 账户
     * @param {Object} payload password {String} 密码
     * @param {Object} payload route {Object} 登录成功后定向的路由对象 任何 vue-router 支持的格式
     */
    loginByAccount({ rootState, dispatch }, loginForm) {
      return new Promise((resolve, reject) => {
        // 对 loginForm 对象指定字段加密
        const form = util.security.objectEncryption({
          data: loginForm,
          param: ["password"], // 需要加密的字段
        });

        // 开始请求登录接口
        _LoginByAccount(form)
          .then((res) => {
            // 从返回结果中获取数据
            const data = res.data;
            // 设置用户 ID
            util.cookies.set("uuid", data.user_id);
            // 定义 Token
            const token = {
              accessToken: data.access_token,
              refreshToken: data.refresh_token,
              expriresOn: util.getExpiresOn(data.expires_in),
            };
            // 使用 Cookie 存储 Token
            util.cookies.set("token", JSON.stringify(token));

            // 获取并存储用户信息
            dispatch("getUserInfo");

            // 结束
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    /**
     * @description 刷新用户 token
     * @param {Object} context
     */
    refreshToken({ commit, state }) {
      const refreshToken = util.getToken().refreshToken; // 获取刷新令牌
      return new Promise((resolve, reject) => {
        _RefreshToken(refreshToken)
          .then((response) => {
            const data = response.data;
            // 定义 token 对象
            const token = {
              accessToken: data.access_token,
              refreshToken: data.refresh_token,
              expriresOn: util.getExpiresOn(data.expires_in),
            };
            // 使用 session 存储 token
            util.cookies.set("token", JSON.stringify(token));
            resolve(token);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    /**
     * @description 注销用户并返回登录页面
     * @param {Object} context
     * @param {Object} payload confirm {Boolean} 是否需要确认
     */
    logout({ commit, dispatch }, { confirm = false } = {}) {
      /**
       * @description 注销
       */
      function logout() {
        console.log("nima");
        // 清空用户 session
        util.cookies.remove("token");
        // 清空 vuex 用户信息
        dispatch("user/setInfo", {}, { root: true });
        // 清空 vuex 用户角色信息
        dispatch("user/setRoles", {}, { root: true });
        // 清空 vuex 用户权限信息
        dispatch("user/setPermissions", {}, { root: true });
        // 跳转路由
        if (router.app._route.name === "index") {
          router.go(0);
        } else {
          router.push({ path: "/index" });
        }
      }
      // 判断是否需要确认
      if (confirm) {
        commit("gray/set", true, { root: true });
        MessageBox.confirm("确定要退出当前用户吗", "退出用户", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            commit("gray/set", false, { root: true });
            // 后端退出后,前台退出
            _Logout().then(() => {
              logout(); // 前端退出
            });
          })
          .catch(() => {
            commit("gray/set", false, { root: true });
            Message({
              message: "取消注销操作",
            });
          });
      } else {
        // logout()
        // 后端退出后,前台退出
        _Logout().then(() => {
          logout(); // 前端退出
        });
      }
    },

    /**
     * @description 获取用户信息
     * @param {Object} context
     */
    getUserInfo({ dispatch }) {
      return new Promise((resolve, reject) => {
        _GetUserInfo()
          .then((res) => {
            // 存储用户信息
            dispatch("user/setInfo", res.sysMember, { root: true });
            // 存储角色信息
            dispatch("user/setRoles", res.roles || [], { root: true });
            // 存储权限信息
            dispatch("user/setPermissions", res.permissions || [], {
              root: true,
            });
            resolve(res);
          })
          .catch((err) => {
            console.log("err: ", err);
            reject(err);
          });
      });
    },
  },
};
