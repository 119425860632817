/*
 * @Description: 用户中心首页
 * @Author: szw
 * @Date: 2022-10-23 15:28:17
 * @LastEditors: szw
 * @LastEditTime: 2022-10-24 11:09:33
 */
import request from '@/libs/axios'

/**
 * _GetUserRoles
 * @description 获取个人货架总详情
 */
export function _GetUserGoodsNumber () {
  return request({
    url: '/tf-trading/tf-home-page/goods/info',
    method: 'get'
  })
}

/**
 * _GetUserRoles
 * @description 获取个人未签署合同总详情(买家)
 */
export function _GetUserBuyerContractNumber () {
  return request({
    url: '/tf-trading/tf-home-page/buyer/contract/info',
    method: 'get'
  })
}

/**
 * _GetUserRoles
 * @description 获取个人未签署合同总详情(卖家)
 */
export function _GetUserSellerContractNumber () {
  return request({
    url: '/tf-trading/tf-home-page/seller/contract/info',
    method: 'get'
  })
}

/**
 * _GetUserRoles
 * @description 获取个人参加交易会数量详情
 */
export function _GetUserBiddingNumber () {
  return request({
    url: '/tf-trading/tf-home-page/fair/info',
    method: 'get'
  })
}

/**
 * _GetUserRoles
 * @description 获取个人收到的信息
 */
export function _GetUserMessage (params) {
  return request({
    url: '/tf-trading/tf-home-page/message/info',
    method: 'get',
    params: { ...params }
  })
}

/**
 * _GetUserRoles
 * @description 获取个人账户金额详情
 */
export function _GetUserAccountInfo () {
  return request({
    url: '/tf-trading/tf-home-page/money/info',
    method: 'get'
  })
}

/**
 * _GetUserRoles
 * @description 获取个人交易产品数量详情
 */
export function _GetUserGoodsInfo () {
  return request({
    url: '/tf-trading/tf-home-page/order/info',
    method: 'get'
  })
}

/**
 * _GetUserRoles
 * @description 用户已读自己的所有消息
 */
export function _ReadAllMessage () {
  return request({
    url: '/tf-trading/tf-member-message/read',
    method: 'put'
  })
}

/**
 * _GetUserRoles
 * @description 获取首页近两天皮棉交易情况
 */
export function _GetIndexLintData () {
  return request({
    url: '/tf-trading/tf-order/lint/data',
    method: 'get'
  })
}

/**
 * _GetUserRoles
 * @description 获取首页订单总计数据统计
 */
export function _GetIndexTotalData () {
  return request({
    url: '/tf-trading/tf-order/total',
    method: 'get'
  })
}

/**
 * _GetUserRoles
 * @description 获取首页交易动态
 */
export function _GetIndexDynamic () {
  return request({
    url: '/tf-trading/tf-order/trade/dynamic',
    method: 'get'
  })
}

/**
 * _GetUserRoles
 * @description 查询首页期货最新现价情况
 */
export function _GetIndexContractNow () {
  return request({
    url: '/tf-trading/tf-futures/contract/now',
    method: 'get'
  })
}

/**
 * _GetUserRoles
 * @description 查询首页期货走势
 */
export function _GetIndexContractChart (params) {
  return request({
    url: '/tf-trading/tf-futures/contract/chart',
    method: 'get',
    params: { ...params }
  })
}

/**
 * _GetUserRoles
 * @description 查询首页现货走势
 */
export function _GetIndexSpotChart () {
  return request({
    url: '/tf-trading/tf-futures/spot/chart',
    method: 'get'
  })
}

/**
 * _GetUserRoles
 * @description 用户获取个人未读信息数量
 */
export function _GetIndexUnreadMessage () {
  return request({
    url: '/tf-trading/tf-member-message/unread/count',
    method: 'get'
  })
}

/**
 * _GetUserRoles
 * @description 获取个人交易未签署合同整体数量详情
 */
export function _GetUserContractNumber () {
  return request({
    url: '/tf-trading/tf-home-page/total/contract/info',
    method: 'get'
  })
}

/**
 * _GetUserRoles
 * @description 查询用户自己的认证信息列表
 */
export function _GetUserApproveList () {
  return request({
    url: '/tf-trading/tf-member-authentication/user/list',
    method: 'get'
  })
}
