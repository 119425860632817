/*
 * @Description: 网站设置
 * @Author: Pancras
 * @Date: 2022-08-04 11:08:53
 * @LastEditors: adlj
 * @LastEditTime: 2022-08-04 12:46:31
 */
import { _GetSiteInfo } from '@/api/news'

export default {
  namespaced: true,

  state: {
    siteInfo: null
  },
  mutations: {
    setSetting (state, payload) {
      state.siteInfo = payload
    }
  },
  actions: {
    getSetting (ctx) {
      _GetSiteInfo().then(res => {
        ctx.commit('setSetting', res)
      })
    }
  }
}
