/*
 * @Description: Axios 接口配置文件
 * @Author: Pancras
 * @Date: 2019-10-24 14:17:51
 * @LastEditors: szw
 * @LastEditTime: 2022-12-07 14:23:32
 */
import store from '@/store'
import router from '@/router'
import axios from 'axios'
import Qs from 'qs'
import util from '@/libs/util'
import setting from '@/setting'
import errorCode from '@/const/errorCode'

// 返回其他状态码(否则自定义 errorCode 不会被捕捉)
axios.defaults.validateStatus = status => {
  return status >= 200 && status <= 500 // 默认的
}

// 创建一个 axios 实例
const service = axios.create({
  baseURL: process.env.VUE_APP_API + 'api',
  timeout: 100000, // 请求超时时间
  // 参数序列化
  paramsSerializer: function (params) {
    return Qs.stringify(params, { arrayFormat: 'repeat', allowDots: true, skipNulls: true })
  }
})

// 请求拦截器
service.interceptors.request.use(
  config => {
    // 从 Session 中获取 Token
    const token = util.getToken()
    // 从配置文件获取租户 ID
    const TENANT_ID = setting.server.tenantId
    // 判断是否是获取 Token 的请求
    const isToken = (config.headers || {}).isToken === false
    // 如果需要携带 Token
    if (token && token !== 'undefined' && !isToken) {
      config.headers.Authorization = 'Bearer ' + token.accessToken
    }
    // 每个请求添加租户 ID
    config.headers['TENANT-ID'] = TENANT_ID
    return config
  },
  (error, request) => {
    // Promise 失败
    return Promise.reject(error)
  }
)

// 响应拦截器
service.interceptors.response.use(
  response => {
    const status = Number(response.status) || 200
    const message = response.data.msg || errorCode[status] || errorCode.default
    // 当前操作没有权限
    if (status === 401) {
      store.dispatch('account/logout').then(() => {
        router.push({ path: '/login' })
      })
      return
    }

    // Token 过期
    if (status === 424) {
      // 退出登录
      store.dispatch('account/logout').then(() => {
        router.push({ path: '/login' })
      })
      return
    }

    // 接口请求成功,但是服务器返回自定义错误
    if (status !== 200 || response.data.code === 1) {
      // Promise 失败
      return Promise.reject(new Error(message))
    }
    // 服务端自定义返回码 0:成功 1:失败
    const { code } = response.data

    // 无 Code 为非 Token 请求,可能是登录或验证码等请求
    if (code === undefined) {
      return response
    } else {
      // 接口请求返回服务端数据,而不是 Response
      return response.data.data
    }
  },

  error => {
    return Promise.reject(error)
  }
)

export default service
