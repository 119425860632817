/*
 * @Description: Store：灰度配置
 * @Author: Pancras
 * @Date: 2022-06-20 12:27:42
 * @LastEditors: Pancras
 * @LastEditTime: 2022-07-08 17:00:41
 */
export default {
  namespaced: true,
  state: {
    // 灰度
    active: false
  },
  mutations: {

    /**
     * @description 切换灰度状态
     * @param {Object} state state
     */
    toggle (state) {
      state.active = !state.active
    },

    /**
     * @description 设置灰度模式
     * @param {Object} state state
     * @param {Boolean} active active
     */
    set (state, active) {
      state.active = active
    }

  }
}
